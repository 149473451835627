import React from "react";
import { Box, TextField } from "@material-ui/core";

export const UsernameStep = (props: any): JSX.Element => {
  return (
    <Box margin={2}>
      <TextField
        label={"Enter your username..."}
        onChange={props.onChange}
        style={{ width: "200px" }}
        value={props.username}
        name="username"
      />
    </Box>
  );
};
