import React, { useState } from "react";
import { Button, Box, Grid } from "@material-ui/core";

export const Hover = (): JSX.Element => {
  const [showButtons, setShowButtons] = useState(false);
  const [showContent, setShowContent] = useState(false);

  return (
    <Box>
      <Box margin={2}>
        <Button
          variant="contained"
          color="primary"
          onMouseOver={() => setShowButtons(true)}
        >
          Hover Here
        </Button>
      </Box>
      <Box margin={2}>
        {showButtons && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowContent(true)}
          >
            Click Me
          </Button>
        )}
      </Box>
      <Box margin={2}>
        {showContent && (
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <ul>
                <li>Item 1</li>
                <li>Item 2</li>
                <li>Item 3</li>
                <li>Item 4</li>
                <li>Item 5</li>
                <li>Item 6</li>
                <li>Item 7</li>
                <li>Item 8</li>
                <li>Item 9</li>
                <li>Item 10</li>
              </ul>
            </Grid>
            <Grid item xs={2}>
              <ul>
                <li>Item 11</li>
                <li>Item 12</li>
                <li>Item 13</li>
                <li>Item 14</li>
                <li>Item 15</li>
                <li>Item 16</li>
                <li>Item 17</li>
                <li>Item 18</li>
                <li>Item 19</li>
                <li>Item 20</li>
              </ul>
            </Grid>
            <Grid item xs={2}>
              <ul>
                <li>Item 21</li>
                <li>Item 22</li>
                <li>Item 23</li>
                <li>Item 24</li>
                <li>Item 25</li>
                <li>Item 26</li>
                <li>Item 27</li>
                <li>Item 28</li>
                <li>Item 29</li>
                <li>Item 30</li>
              </ul>
            </Grid>
            <Grid item xs={2}>
              <ul>
                <li>Item 31</li>
                <li>Item 32</li>
                <li>Item 33</li>
                <li>Item 34</li>
                <li>Item 35</li>
                <li>Item 36</li>
                <li>Item 37</li>
                <li>Item 38</li>
                <li>Item 39</li>
                <li>Item 40</li>
              </ul>
            </Grid>
            <Grid item xs={2}>
              <ul>
                <li>Item 41</li>
                <li>Item 42</li>
                <li>Item 43</li>
                <li>Item 44</li>
                <li>Item 45</li>
                <li>Item 46</li>
                <li>Item 47</li>
                <li>Item 48</li>
                <li>Item 49</li>
                <li>Item 50</li>
              </ul>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};
