import { authenticator } from "otplib";

export const isValidMFACode = (code: string): boolean => {
  let secretKey = "AAAA BBBB CCCC DDDD EEEE FFFF GGGG HHHH";
  secretKey = secretKey.replace(/ /g, "");

  const otpCodes: string[] = [];

  otpCodes.push(authenticator.generate(secretKey));

  authenticator.options = {
    epoch: Date.now() + authenticator.timeRemaining() * 1000 + 15000,
  };

  otpCodes.push(authenticator.generate(secretKey));

  authenticator.options = {
    epoch: Date.now() + authenticator.timeRemaining() * 1000 + 45000,
  };

  otpCodes.push(authenticator.generate(secretKey));

  return otpCodes.includes(code);
};
