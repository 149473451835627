import React, { useState, ChangeEvent } from "react";
import {
  CssBaseline,
  Box,
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import { UsernameStep } from "./UsernameStep";
import { PasswordStep } from "./PasswordStep";
import { MFAStep } from "./MFAStep";
import { isValidMFACode } from "./helpers/helpers";

const steps = ["Username", "Password", "MFA"];

const theme = createTheme();

console.log(`***`);

export const LoginSinglePage = (): JSX.Element => {
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState("");

  const [username, setUsername] = useState("");
  const handleUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const [password, setPassword] = useState("");
  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const [mfa, setMfa] = useState("");
  const handleMFAChange = (event: ChangeEvent<HTMLInputElement>) => {
    setMfa(event.target.value);
  };

  function getStepContent(step: number) {
    console.log(`getStepContent: step ${step}`);
    switch (step) {
      case 0:
        return (
          <UsernameStep onChange={handleUsernameChange} username={username} />
        );
      case 1:
        return (
          <PasswordStep onChange={handlePasswordChange} password={password} />
        );
      case 2:
        return <MFAStep onChange={handleMFAChange} mfa={mfa} />;
      default:
        throw new Error("Unknown step");
    }
  }

  const handleNext = () => {
    console.log(`handleNext`);
    if (activeStep === 0 && username !== "ScreenCloud") {
      setError("Incorrect Username");
    } else if (activeStep === 1 && password !== "Password") {
      setError("Incorrect Password");
    } else if (activeStep === 2) {
      console.log(`isValidMFACode`);
      const codeValid: boolean = isValidMFACode(mfa);
      if (!codeValid) {
        setError("Incorrect MFA Code");
      } else {
        setError("");
        setActiveStep(activeStep + 1);
      }
    } else {
      setError("");
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    console.log(`handleBack`);
    setActiveStep(activeStep - 1);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="sm">
        <Paper variant="outlined">
          <Typography component="h1" variant="h4" align="center">
            Login
          </Typography>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Box margin={2}>
                  <Typography variant="h5" gutterBottom>
                    Login Success
                  </Typography>
                  <Typography variant="subtitle1">
                    You have successfully logged in. Please choose a report
                  </Typography>
                </Box>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                {error !== "" && <Alert severity="error">{error}</Alert>}
                <Box
                  margin={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {activeStep !== 0 && (
                    <Button onClick={handleBack}>Back</Button>
                  )}
                  <Button variant="contained" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </Box>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};
