import React from "react";
import { Box, TextField } from "@material-ui/core";

export const PasswordStep = (props: any): JSX.Element => {
  return (
    <Box margin={2}>
      <TextField
        type={"password"}
        label={"Enter your password..."}
        onChange={props.onChange}
        style={{ width: "200px" }}
        value={props.password}
        name="password"
      />
    </Box>
  );
};
