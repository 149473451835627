import React, { useState, Fragment } from "react";

const elementStyle = {
  height: "8vw",
  width: "8vw",
  margin: "0.5vw",
  display: "inline-block",
  background: "#ddd",
  "vertical-align": "top",
}

const getRandomCats = () => {
  const random = Math.trunc(Math.random() * 10);

  const catArray = [];

  for (let i = 0; i <= random; i++) {
    const image = (
      <img
        src={`https://cataas.com/cat/says/${i+1}?s=large`}
        id={`Cat-${i}`}
        alt="A Cat"
        style={elementStyle}
      />
    );

    catArray.push(image);
  }

  for (let i = random + 1; i < 10; i++) {
    const blank = (
      <div
        style={elementStyle}
      >
        {i + 1}
      </div>
    );

    catArray.push(blank);
  }

  return catArray;
}

export const RandomCats = (): JSX.Element => {
  const [toggle, setToggle] = useState(false);

  // Needed to refresh DOM
  setTimeout(() => {
    if (toggle === true) {
      setToggle(false);
    } else if (toggle === false) {
      setToggle(true);
    }
  }, 30000);

  const content = (
    <Fragment>
      <div>{getRandomCats()}</div>
    </Fragment>
  );

  return (
    <div>
      <p>
        This page will display up to 10 cat photos.
        Its useful for testing coordinate fallback on missing elements
      </p>
      <br />
      <Fragment>{toggle ? <div>{content}</div> : content}</Fragment>
    </div>
  );
};
