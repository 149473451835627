import { Button } from "@material-ui/core";
import React from "react";

const randomiseIntervalMillis = 1000 * 20;

const randomColours = ["#ffc4c7", "#ffea9f", "#0a269e", "#0875c4", "#05d7f1"];

const divWrapper1Style = {
  height: 120,
  width: 120,
  margin: 10,
  background: "#D3D3D3",
  display: "inline-block",
};

const divWrapper2Style = {
  height: 140,
  width: 140,
  margin: 10,
  background: "#808080",
  display: "inline-block",
};

let randomButtonArray: JSX.Element[] = [];

let numberOfButtonsToGenerate = 10;

const getCurrentRoundedTime = (): Date => {
  const timeRoundcoeff = 1000 * 60 * 5;
  const currentDate = new Date();
  const currentTimestampRounded = new Date(
    Math.floor(currentDate.getTime() / timeRoundcoeff) * timeRoundcoeff
  );

  return currentTimestampRounded;
};

const idFromTimestamp = (timestamp: Date): string => {
  const formattedTimestamp: string =
    ("0" + timestamp.getUTCDate()).slice(-2) +
    "-" +
    ("0" + (timestamp.getUTCMonth() + 1)).slice(-2) +
    "-" +
    timestamp.getUTCFullYear() +
    "-" +
    ("0" + timestamp.getUTCHours()).slice(-2) +
    "-" +
    ("0" + timestamp.getUTCMinutes()).slice(-2);

  return formattedTimestamp;
};

const isOdd = (number: number): boolean => {
  if (number % 2 === 0) {
    return false;
  } else {
    return true;
  }
};

const boolFromMinuteValues = (minutesValue: number): boolean => {
  if (
    minutesValue === 0 ||
    minutesValue === 10 ||
    minutesValue === 20 ||
    minutesValue === 30 ||
    minutesValue === 40 ||
    minutesValue === 50
  ) {
    return true;
  } else {
    return false;
  }
};

const generateRandomButtons = () => {
  randomButtonArray = [];

  const currentRoundedTimestamp: Date = getCurrentRoundedTime();

  if (boolFromMinuteValues(currentRoundedTimestamp.getMinutes()) === true) {
    numberOfButtonsToGenerate = 10;
  } else {
    numberOfButtonsToGenerate = 20;
  }

  const isDivWrapper1: boolean = isOdd(currentRoundedTimestamp.getHours());

  const isDivWrapper2: boolean = boolFromMinuteValues(
    currentRoundedTimestamp.getMinutes()
  );

  for (let gbc = 0; gbc <= numberOfButtonsToGenerate; gbc++) {
    const randomButtonId =
      "button-" + gbc + "-" + idFromTimestamp(currentRoundedTimestamp);

    const divWrapper1Id: string =
      "wrapper-1-" + gbc + "-" + idFromTimestamp(currentRoundedTimestamp);
   
    const divWrapper2Id: string =
      "wrapper-2-" + gbc + "-" + idFromTimestamp(currentRoundedTimestamp);

    let randomButton: JSX.Element;

    const randomColourIndex = Math.floor(Math.random() * randomColours.length);

    const randomButtonStyling = {
      background: randomColours[randomColourIndex],
      width: 100,
      height: 100,
      margin: 10,
      display: "inline-block",
    };
    if (
      (isDivWrapper1 && !isDivWrapper2) ||
      (isDivWrapper2 && !isDivWrapper1)
    ) {
      randomButton = (
        <div id={divWrapper1Id} style={divWrapper1Style}>
          <Button
            id={randomButtonId}
            variant="contained"
            onClick={() => setButtonClicked()}
            style={randomButtonStyling}
          />
        </div>
      );
    } else if (isDivWrapper1 && isDivWrapper2) {
      randomButton = (
        <div id={divWrapper2Id} style={divWrapper2Style}>
          <div id={divWrapper1Id} style={divWrapper1Style}>
            <Button
              id={randomButtonId}
              variant="contained"
              onClick={() => setButtonClicked()}
              style={randomButtonStyling}
            />
          </div>
        </div>
      );
    } else {
      randomButton = (
        <Button
          id={randomButtonId}
          variant="contained"
          onClick={() => setButtonClicked()}
          style={randomButtonStyling}
        />
      );
    }

    randomButtonArray.push(randomButton);
  }

  if (numberOfButtonsToGenerate === 10) {
    // Show blanks where buttons would be
    for (let i = 0; i < numberOfButtonsToGenerate; i++) {

      let filler: JSX.Element;

      let size = 100;

      if (isDivWrapper1 && isDivWrapper2) {
        size = 140;
      } else if (isDivWrapper1 || isDivWrapper2) {
        size = 120;
      }
  
      const fillerStyling = {
        background: "#aaaaaa",
        width: size,
        height: size,
        margin: 10,
        display: "inline-block",
        "vertical-align": "top",
      };

      const blankNumber = i + 11;
     
      filler = (
        <div style={fillerStyling}>
          {blankNumber}
        </div>
      );

      randomButtonArray.push(filler);
    }
  }
}

const setButtonClicked = () => {
  // Do nothing
};

generateRandomButtons();

setInterval(() => generateRandomButtons(), randomiseIntervalMillis);

export const getRandomButtonArray = (): JSX.Element[] => {
  return randomButtonArray;
};
