import React, { useState } from "react";

export const Sensors = (): JSX.Element => {
  if ("DeviceOrientationEvent" in window) {
    window.addEventListener(
      "deviceorientation",
      deviceOrientationHandler,
      false
    );
  } else {
    console.log("Device Orientation API not supported.");
  }

  const [tiltLR, setTiltLR] = useState(0);
  const [tiltFB, setTiltFB] = useState(0);
  const [dir, setDir] = useState(0);

  function deviceOrientationHandler(eventData: any) {
    setTiltLR(Math.round((eventData.gamma + Number.EPSILON) * 100) / 100);
    setTiltFB(Math.round((eventData.beta + Number.EPSILON) * 100) / 100);
    setDir(Math.round((eventData.alpha + Number.EPSILON) * 100) / 100);
  }

  /*const sensor = new Accelerometer({
    frequency: 1,
  });
  sensor.start();

  sensor.onreading = () => {
    console.log("Acceleration along X-axis: " + sensor.x);
    console.log("Acceleration along Y-axis: " + sensor.y);
    console.log("Acceleration along Z-axis: " + sensor.z);
    setX(sensor.x || 0);
    setY(sensor.y || 0);
    setZ(sensor.z || 0);
  };

  sensor.onerror = (event) =>
    console.log(event.error.name, event.error.message);*/

  return <h1>{`TiltLR: ${tiltLR} TiltFB: ${tiltFB} Dir: ${dir}`}</h1>;
};
