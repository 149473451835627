import React from 'react';
import { Button } from '@material-ui/core'

export const ClickDelayRedirect = (): JSX.Element => {

    const setButtonClicked = () => {
        setTimeout(() => {
            window.location.href = "https://www.google.com";
        }, 8000);
    };
    const setButtonClickedLongDelay = () => {
        setTimeout(() => {
            window.location.href = "https://www.google.com";
        }, 120000);
    };
                    
    return (       
        <div>
            <Button variant="contained" color="primary" onClick={() => setButtonClicked()}>Press Me</Button>
            <br></br>
            <br></br>
            <Button variant="contained" color="primary" onClick={() => setButtonClickedLongDelay()}>Press Me For Longer Delay</Button>                 
        </div>
    );
};