import React, { useState } from 'react';
import { Box, TextField, Button, Grid, Checkbox, FormControlLabel, FormControl, Radio, RadioGroup, FormLabel, InputLabel, MenuItem, Select } from '@material-ui/core'

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';

export const SimpleActions = (): JSX.Element => {

    const [buttonClicked, setButtonClicked] = useState(false);
    const [checkboxClicked, setCheckboxClicked] = useState(false);
    const [radioSelection, setRadioSelection] = useState("");
    const [selectSelection, setSelectSelection] = useState(0);
    const [textUsername, setTextUsername] = useState("");
    const [textPassword, setTextPassword] = useState("");

    const handleRadioChange = (e: any) => {
        const { value } = e.target;
        setRadioSelection(value);
    };

    const handleSelectChange = (e: any) => {
        const { value } = e.target;
        setSelectSelection(value);
    };

    const handleTextUsernameChange = (e: any) => {
        const { value } = e.target;
        setTextUsername(value);
    };

    const handleTextPasswordChange = (e: any) => {
        const { value } = e.target;
        setTextPassword(value);
    };
            
    return (           
        <Grid container spacing={1}>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={2}>
                    <Box m={2}>
                        <Button variant="contained" color="primary" onClick={() => setButtonClicked(!buttonClicked)}>Press Me</Button>
                    </Box>                    
                </Grid>
                <Grid item xs={2}>
                    <Box m={2}>
                        <FormControlLabel control={
                            <Checkbox
                                checked={checkboxClicked}
                                onChange={() => setCheckboxClicked(!checkboxClicked)}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                value={'abc'}
                            />
                        } label="Click Me " />
                    </Box>                
                </Grid>
                <Grid item xs={2}>
                    <Box m={2}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Answer to the Ultimate Question of Life, the Universe, and Everything</FormLabel>
                            <RadioGroup aria-label="question" name="question" value={radioSelection} onChange={handleRadioChange}>
                                <FormControlLabel value={41} control={<Radio checked={radioSelection === "41"} />} label="41" />
                                <FormControlLabel value={42} control={<Radio checked={radioSelection === "42"} />} label="42" />
                                <FormControlLabel value={43} control={<Radio checked={radioSelection === "43"} />} label="43" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box m={2}>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Worst Year</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={selectSelection}
                                onChange={handleSelectChange}
                                fullWidth>
                                <MenuItem disabled={true} value={0}>Select a year</MenuItem>
                                <MenuItem value={2010}>2010</MenuItem>
                                <MenuItem value={2015}>2015</MenuItem>
                                <MenuItem value={2020}>2020</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box m={2}>
                        <FormControl>
                            <TextField id="standard-username" label="Type ScreenCloud" aria-label="Username" onChange={handleTextUsernameChange} />                        
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box m={2}>
                        <FormControl>
                            <TextField id="standard-password" type="password" label="Type Password" onChange={handleTextPasswordChange} />
                        </FormControl>
                    </Box>  
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={2}>
                    <Box m={2}>
                        {buttonClicked 
                            ? <CheckCircleIcon color="primary" />
                            : <ClearIcon color="secondary" />
                        }
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box m={2}>
                        {checkboxClicked 
                            ? <CheckCircleIcon color="primary" />
                            : <ClearIcon color="secondary" />
                        }
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box m={2}>
                        {(radioSelection === "42")
                            ? <CheckCircleIcon color="primary" />
                            : <ClearIcon color="secondary" />
                        }
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box m={2}>
                        {(selectSelection === 2020)
                            ? <CheckCircleIcon color="primary" />
                            : <ClearIcon color="secondary" />
                        }
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box m={2}>
                        {(textUsername === "ScreenCloud")
                            ? <CheckCircleIcon color="primary" />
                            : <ClearIcon color="secondary" />
                        }
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box m={2}>
                        {(textPassword === "Password")
                            ? <CheckCircleIcon color="primary" />
                            : <ClearIcon color="secondary" />
                        }
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
};