import * as React from "react";
import {
  HashRouter as BrowserRouter,
  Route,
  Link,
  Switch,
} from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Box,
  Menu,
  MenuItem,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";

//import {Index} from '../../pages/Index';
import { SimpleActions } from "../../pages/SimpleActions";
import { IFrameActions } from "../../pages/IFrameActions";
import { ButtonRandomId } from "../../pages/ButtonRandomId";
import { RandomIdAndColourDivs } from "../../pages/RandomIdAndColourDivs";
import { RandomCats } from "../../pages/RandomCats";
import { ClickDelayRedirect } from "../../pages/ClickDelayRedirect";
import { LoginSinglePage } from "../../pages/login/LoginSinglePage";
import { LoginMultiPage } from "../../pages/login/LoginMultiPage";
import { Hover } from "../../pages/actions/Hover";
import { Sensors } from "../../pages/Sensors";
import { createTheme } from "@material-ui/core/styles";
import green from "@material-ui/core/colors/green";
import red from "@material-ui/core/colors/red";
import { ThemeProvider } from "@material-ui/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
    secondary: {
      main: red[500],
    },
  },
});

export const AppContainer = (): JSX.Element => {
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });

  return (
    <BrowserRouter>
      <div>
        <ThemeProvider theme={theme}>
          <AppBar
            position="absolute"
            color="default"
            elevation={0}
            style={{
              position: "relative",
              borderBottom: `1px solid black`,
            }}
          >
            <Toolbar>
              <Grid
                justify="space-between" // Add it here :)
                container
                spacing={2}
              >
                <Typography variant="h6" color="inherit" noWrap>
                  Dashboards Proving Grounds
                </Typography>
                <nav>
                  <Button variant="contained" {...bindTrigger(popupState)}>
                    Navigate
                  </Button>
                  <Menu {...bindMenu(popupState)}>
                    <MenuItem onClick={popupState.close}>
                      <Link to="/simpleactions">Simple Actions</Link>
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                      <Link to="/iframeactions">IFrame Actions</Link>
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                      <Link to="/clickdelayredirect">Click Delay Redirect</Link>
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                      <Link to="/buttonrandomid">Button Random Id</Link>
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                      <Link to="/randomidandcolourdivs">
                        Random Id and Colour Divs
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                      <Link to="/loginsinglepage">Login (Single Page)</Link>
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                      <Link to="/loginmultipage">Login (Multi Page)</Link>
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                      <Link to="/hover">Hover</Link>
                    </MenuItem>
                    <MenuItem onClick={popupState.close}>
                      <Link to="/cats">Cats</Link>
                    </MenuItem>
                  </Menu>
                </nav>
              </Grid>
            </Toolbar>
          </AppBar>
          <Box margin={2}>
            <Switch>
              <Route path="/simpleactions" component={SimpleActions} />
              <Route path="/iframeactions" component={IFrameActions} />
              <Route
                path="/clickdelayredirect"
                component={ClickDelayRedirect}
              />
              <Route path="/buttonrandomid" component={ButtonRandomId} />
              <Route
                path="/randomidandcolourdivs"
                component={RandomIdAndColourDivs}
              />
              <Route path="/loginsinglepage" component={LoginSinglePage} />
              <Route path="/loginmultipage" component={LoginMultiPage} />
              <Route path="/hover" component={Hover} />
              <Route path="/sensors" component={Sensors} />
              <Route path="/cats" component={RandomCats} />
            </Switch>
          </Box>
        </ThemeProvider>
      </div>
    </BrowserRouter>
  );
};
