import React, { useState, Fragment } from "react";
import { getRandomButtonArray } from "./helpers/RandomIdAndColourGenerator";

export const RandomIdAndColourDivs = (): JSX.Element => {
  const [toggle, setToggle] = useState(false);

  // Needed to refresh DOM
  setTimeout(() => {
    if (toggle === true) {
      setToggle(false);
    } else if (toggle === false) {
      setToggle(true);
    }
  }, 5000);

  const content = (
    <Fragment>
      <div>{getRandomButtonArray()}</div>
    </Fragment>
  );

  return (
    <div>
      <p>
        This page will generate 10 or 20 blank buttons every 5 minutes (on
        xx:00, xx:05, xx:10, etc...)
        <br />
        Each button&apos;s id is based on the current timestamp rounded down to
        5 minutes. None, one, or two wrapper divs will also be generated for
        each button depending on the time.
        <br />
        Dashboard should record jounrey and initially render successfully, but
        should fail in subsequent runs.
        <br />
        Useful for testing image and error handling when a recent successful
        snapshot already exists.
      </p>
      <br />
      <Fragment>{toggle ? <div>{content}</div> : content}</Fragment>
    </div>
  );
};
