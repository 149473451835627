import React, { useState, Fragment } from 'react';
import { Button } from '@material-ui/core'
const { v4: uuidv4 } = require('uuid');

export const ButtonRandomId = (): JSX.Element => {

    const [errorLabel, setErrorLabel] = useState("");
    const [showWrapper, setShowWrapper] = useState(false);

    var randomId: string = uuidv4();

    const setButtonClicked = () => {
        setTimeout(() => {
            window.location.href = "https://www.google.com";
        }, 1000);
    };
   
    setTimeout(() => {
        setShowWrapper(true);
        setErrorLabel("Whoops something bad happened!");
    }, 5000);
    
    const content = (
        <Fragment>
          <Button id={randomId} variant="contained" color="primary" onClick={() => setButtonClicked()}>Press Me</Button>
        </Fragment>
    );
  

    return (       
        <div>
            <p>
                This page will generate a random id every time for the button to allow it to be recorded 
                in Dashboards correctly on recording (as long as clicked within 5 seconds)

                When this is being rendererd in the container the id will be different and the click action should fail
                and the error message should be displayed and the navigation to final page wont happen
            </p>
            <Fragment>
                {showWrapper ? (
                    <div style={{ backgroundColor: "red" }}>{content}{errorLabel}</div>
                ) : (
                    content
                )}            
            </Fragment>
        </div>        
    );
};